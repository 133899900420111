import React from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Layout from 'components/layout';

const NotFound = () => {
  const intl = useIntl();
  return (
    <Layout pageTitle={'Page inconnue'}>
      <div className="text-center">
        <p className=" text-6xl text-gray-500 pt-40">
          {intl.formatMessage({ id: 'unknown-page' })}
        </p>
        <Link
          to={'/'}
          className="block text-3xl no-underline text-gray-600 pt-10 pb-40"
        >
          {intl.formatMessage({ id: 'return-home' })}
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
